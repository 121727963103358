.log-in {
    display: flex;
    flex-direction: column; /* 자식 요소를 세로로 나열 */
    align-items: center;     /* 수직 중앙 정렬 */
    width: 100%;
    height: 880px;
    margin-top: 220px;
}

.Mypage {
    display: flex;
    flex-direction: column; /* 자식 요소를 세로로 나열 */
    align-items: center;     /* 수직 중앙 정렬 ,, 임시css */ 
    width: 100%;
    height: 880px;
    margin-top: 150px;
}

.login-form {
    display: flex;
    flex-direction: column; /* 자식 요소를 세로로 나열 */
    align-items: center;     /* 수직 중앙 정렬 */
    background-color: #fff;
    padding: 30px 30px 30px 30px;
    border-radius: 10px; /* 모서리 둥글게 */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 30%;
    min-width: 400PX;
    height: 50%;
    position: relative; /* 폼 위치 조정 */
   
}

.input-section {
    margin: 5px 0 10px 0;
    padding: 0 10px 0 10px;
    width: 300px;
   height: 40px;
   border: 2px solid rgba(0, 0, 0, 0.1);
   border-radius: 5px; /* 모서리 둥글게 */
   display: block; /* input을 블록 요소로 만듦 */
 
}

.submit-login-btn {
    margin-top: 10px;
    width: 300px;
    height: 40px;
    border-radius: 5px; /* 모서리 둥글게 */
    background-color: #0554f2;
    border: none;
    color: white;
}

.signup-link-btn {
    border: 2px solid rgba(0, 0, 0, 0.1); /* 연한 테두리 */
    margin-top: 30px;
    width: 300px;
    height: 40px;
    border-radius: 5px; /* 모서리 둥글게 */
    background-color: white;
    color: #333;
}

.custom-line {
    border: none; /* 기본 테두리를 없앰 */
    border-top: 1px solid #ccc; /* 연한 회색의 수평선 */
    margin: 30px 0; /* 수직 여백 */
    width: 60%; /* 전체 너비 */
}


