
  /* 단계 섹션 스타일 */
  .Step-Section {
    display: flex;
    justify-content: space-between; /* 자식 요소들 사이의 간격을 균등하게 설정 */
    list-style: none; /* 리스트 점 없애기 */
    padding: 0 10px 0 10px; /* 기본 패딩 제거 */
    width: 100%; /* 부모 요소의 너비를 전체로 설정 */
  }
  
  /* 단계 스타일 */

  
  /* 단계 텍스트 스타일 */
  .Step-Text {
    margin-top: 10px; /* 상단 여백 설정 */
  }
  
  /* 진행 바 스타일 */
  .progress-bar-container {
    position: relative;
    height: 20px; /* 고정 높이 */
    width: 100%; /* 고정 너비 */
    background-color: #ece8e8; /* 배경 색상 */
    border-radius: 13px; /* 둥근 모서리 */
    margin: 20px 0; /* 상하 여백 설정 */
  }
  
  .progress {
    height: 20px; /* 고정 높이 */
    background-color: #0554f2; /* 진행 상태 색상 */
    border-radius: 13px; /* 둥근 모서리 */
    transition: width 0.3s; /* 진행 애니메이션 */
  }

  /*-------------------------sidebar-----------------------------------------------------------*/

  .sidebar {
    margin-top: 0;
    display: flex;
    background-color: #fff;
    padding: 30px 30px 30px 30px;
    border-radius: 10px; /* 모서리 둥글게 */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    height: 100vh;
  }

  @media (max-width: 1000px) {
    .sidebar {
      width: 100vw; /* 뷰포트 너비에 맞게 전체 넓이로 변경 */
      height: 100px; /* 높이를 300px로 변경 */
      padding: 20px; /* 패딩 조정 가능 */
      box-shadow: none;
    }
  }
  
  .side-links {
    width: 200px;
    margin-top: 30px;
    margin-right: 30px;
    list-style: none;
    text-align: center; /* 텍스트 가운데 정렬 */
    display: flex; /* Flexbox for alignment */
    flex-direction: column; /* 기본적으로 수직 배치 */
    gap: 100px; /* Space between links */
  }
  
  @media (max-width: 1000px) {
    .side-links {
      width: 600px;
      flex-direction: row; /* 수평 배치로 변경 */
      gap: 30px; /* 링크 간의 간격을 수평 배치에 맞게 조정 */
    }
  
    .side-links a {
      display: flex; /* 링크를 flex 아이템으로 설정 */
      color: #333;
      text-decoration: none;
      font-size: 0.8rem;
      flex: 1; /* 각 링크의 너비를 균등하게 분배 */
      text-align: center; /* 텍스트를 가운데 정렬 */
      justify-items: center;
    }
  }

  .side-links a {
    display: inline-block; /* li를 인라인 블록으로 설정 */
    color: #333;
    text-decoration: none;
    font-size: 0.8rem;
  }

  
  .side-links a:hover {
    color:#0554f2;
    font-weight: bolder;
  }
  
.log-out-btn {
  border: none;
  color: #333;
  font-size: 10pt;
  background-color: white;
}

.log-out-btn:hover {
  color:#0554f2;
  font-weight: bolder;
} 