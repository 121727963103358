:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

/* 웹 최상위 태그 overflow: hidden;  */
.main-container {
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  /* gap: 10px; */
  position: relative;
  width: 100%;
  /* max-width: 1920px; */
  margin: 0 auto;
}
/* 자식태그까지영향,, 콘텐츠 영역, 패딩, 테두리까지 포함하여 계산 */
.main-container,
.main-container * {
  box-sizing: border-box;
}
/*렌딩페이지 속성 */
.landing-page {
  flex-shrink: 0;
  position: relative;
  width: 100%;
  height: auto; /* 높이 자동 */
  background: #ffffff;
  overflow: hidden;
}
/* 모바일 사이즈 반응형 스타일 */
@media (max-width: 577px) {
.main-container {
  flex-direction: column; /* 모바일에서는 세로로 배치 */
  gap: 5px; /* 간격 조정 */
  padding: 5px; /* 모바일에서는 패딩 추가 */
  overflow: auto; /* 모바일에서 오버플로우를 스크롤 가능하게 */
}

.landing-page {
  height: auto; /* 모바일에서는 높이를 자동으로 조정 */
  min-height: 100vh; /* 모바일에서는 화면 높이에 맞게 설정 */
  padding: 20px; /* 모바일에서 패딩 추가 */
}
}


/* 포커스 시 외부테두리 제거 */
input,
select,
textarea,
button {
  outline: 0;
}
/*------------------------------------헤더 상단 내비바---------------------------------*/

.Header {
width: 100%; 

}

.nav {
 
  display: flex; /* Flexbox 레이아웃 활성화 */
  justify-content: space-between; /* 자식 요소를 양 끝에 배치 */
  align-items: center; /* 자식 요소를 수직으로 중앙 정렬 */
  padding: 2% 5%; /* 패딩 조정 */
  background-color: transparent; /* 배경색 설정 */
  position: fixed; /* 고정 위치 */
  width: 100%; /* 너비 100% 설정 */
  margin: 0; /* 마진 제거 */
  box-sizing: border-box; /* 패딩 포함한 크기 계산 */
  z-index: 99; /* 쌓임 순서 설정 */
}


@media (max-width: 576px) {
  .nav {
      display: none; /* 모바일 화면에서는 데스크탑 내비게이션 바 숨김 */
  }

  .m-nav {
      display: flex; /* 모바일 화면에서는 모바일 내비게이션 바 보이도록 설정 */
  }

}

/* 데스크탑 화면 (577px 이상) */
@media (min-width: 577px) {
  .nav {
      display: flex; /* 데스크탑 화면에서는 데스크탑 내비게이션 바 보이도록 설정 */
  }

  .m-nav {
      display: none; /* 데스크탑 화면에서는 모바일 내비게이션 바 숨김 */
  }

  .title {
    font-family: Pretendard, var(--default-font-family);
    font-size: 40pt;
    font-weight: 700;
    text-align: center;
  }
  .sub-title {
    color: #212529;
    font-family: Pretendard, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
  }

  .main-btn-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 196px;
    padding: 20px 40px 20px 40px;
    cursor: pointer;
    background: #0554f2;
    border: none;
    z-index: 44;
    border-radius: 6px;
    transition: transform 0.2s;
  }

  .main-btn-section:hover{
    transform: translateY(-5px); 
  }
  

  .main-btn {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 23px;
    color: #ffffff;
    font-family: Pretendard, var(--default-font-family);
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    text-align: left;
    white-space: nowrap;
    letter-spacing: -0.36px;
    z-index: 83;
  }
  .show {
    display: none;
  }
}

/* 데스크탑 화면 (577px 이하) */
@media (max-width: 577px) {
.title {
  font-size: 20pt; /* 40pt를 대략적으로 2.4em으로 변환 */
  font-weight: 700;
  text-align: center;
}

.sub-title {
  font-size: 14px; /* 18px를 대략적으로 1.2em으로 변환 */
  line-height: 24px; /* 30px를 대략적으로 1.5em으로 변환 */
  text-align: center;
}


.main-btn-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 166px;
  padding: 20px 40px 10px 40px;
  cursor: pointer;
  background: #0554f2;
  border: none;
  z-index: 44;
  border-radius: 6px;
  transition: transform 0.2s;
}

.main-btn-section:hover {
transform: translateY(-5px); 
background: #90b1f5;
}
.main-btn {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 23px;
  color: #ffffff;
  font-family: Pretendard, var(--default-font-family);
  font-size: 12px;
  font-weight: 500;
  line-height: 23px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: -0.36px;
  z-index: 83;
  transition: transform 0.2s;
}

.footer {
  width: 100%;
  padding: 20px 10px; /* 패딩 조정 */
  margin: 0; /* 여백 제거 */
  font-size: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-1, .info-tag {
flex-direction: column; /* 수직 정렬로 변경 */
  align-items: center; /* 중앙 정렬 */
  justify-content: center; /* 중앙 정렬 */
  width: 100%; /* 전체 너비 사용 */
}
.footer-3, .btm-tag {
  flex-direction: column; /* 수직 정렬로 변경 */
  align-items: center; /* 중앙 정렬 */
  justify-content: center; /* 중앙 정렬 */
  width: 100%; /* 전체 너비 사용 */
  height: 100vw;
}

.info-tag, span {
  width: 100%; /* 전체 너비 사용 */
  margin-bottom: 30px; /* 하단 여백 추가 */
  gap: 15px; /* 요소 간의 간격 조정 */
}
.info-tag {
margin-left: 150px;
}


.footer-2  {
  width: 200px;
 text-align: center;
}

.footer-3, .btm-tag {
  text-align: center;
  width: 100%; /* 전체 너비 사용 */
  margin-bottom: 20px; /* 하단 여백 추가 */
  gap: 15px; /* 요소 간의 간격 조정 */
}

.logo-description {
  flex-direction: column;
  align-items: center;
  gap: 10px; /* 간격 조정 */
}

.logo-icon-37, .vector-3a, .vector-3b, .vector-3c {
  width: 100%; /* 전체 너비 사용 */
  height: auto; /* 비율에 맞게 높이 자동 조정 */
}

.logo-35 {
  margin-bottom: 40px;
}
}


/* 아이콘과 글자logo 최상위 태그 */
.logo-link {
  text-decoration: none;
}

.logo {
  gap: 20px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  z-index: 14;
}
/* pflow 아이콘 로고의 조각들.. css*/
.logo-icon {
  width: 37px;
  height: 29px;
  top: 0;
  left: 0;
  z-index: 16;
  overflow: hidden;
}

.group {
  position: relative;
  width: 37px;
  height: 11.487px;
  margin: 0 0 0 0;
  z-index: 18;
}

.vector {
position: absolute;
width: 32.76%;
height: 100%;
top: 0;
left: 0;
background: url(../assets/images/252b1268-0bcd-4d71-a7d3-a14e62553ae6.png)
  no-repeat center;
background-size: 100% 100%;
z-index: 19;
}
.vector-3 {
position: absolute;
width: 32.74%;
height: 99.87%;
top: 0;
left: 67.26%;
background: url(../assets/images/2a2d87f9-4e5e-4f51-8940-f688891402d8.png)
  no-repeat center;
background-size: 100% 100%;
z-index: 20;
}
.vector-4 {
  position: relative;
  width: 37px;
  height: 26.458px;
  margin: -8.942px 0 0 0;
  background: url(../assets/images/3be063ce-1a45-4106-b5dd-c069e3e686a1.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 21;
}
 /* pflow text logo css*/
.pflow {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: #3c3c40;
  font-family: Impact, var(--default-font-family);
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  text-align: left;
  white-space: nowrap;
  text-decoration: none;
  z-index: 22;
 
}

/*-----------------------상단 메뉴바------------------------------------------------- */
.menu {
  display: flex; /* Flexbox로 설정하여 메뉴 요소 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
  gap: 20px; /* 메뉴 아이템 간 간격 */  
}

.tags {
  display: flex; /* 플렉스 박스 레이아웃을 활성화합니다. */
  gap: 25px; /* 태그 간의 간격을 설정합니다. */
  list-style: none; /* 리스트 스타일을 제거합니다. */
  margin: 0; /* 기본 마진을 제거합니다. */
  padding: 0; /* 기본 패딩을 제거합니다. */
}

.tag {
  color: #3c3c40;
  font-family: Pretendard, var(--default-font-family);
  font-size: 14pt;
  font-weight: 500;
  white-space: nowrap;
  text-decoration: none;
}

.tag:hover {
  color: blue;
}

.login {
  text-decoration: none;
}

.login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 40px;
  background: #0554f2;
  color: white;
  border-radius: 8px;
  border: none;
  font-family: Pretendard, var(--default-font-family);
  font-size: 14pt;
  font-weight: 500;
  white-space: nowrap;
  text-decoration: none;
}

.logout-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 40px;
  background: #0554f2;
  color: white;
  border-radius: 8px;
  border: none;
  font-family: Pretendard, var(--default-font-family);
  font-size: 14pt;
  font-weight: 500;
  white-space: nowrap;
  text-decoration: none;
}

/* 모바일 뷰 상단 내비바 css*/
.m-nav {
  width: 100%;
  padding: 10px 20px 0px 20px;
  justify-content: space-between;
}
/* 드롭다운 기본 숨기기 */
.dropdown {
  display: none; /* 기본적으로 숨겨진 상태 */
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: black;
  padding-top: 10%;
  padding-bottom: 10%;
  border-radius: 30px;
  width: 100%; /* 전체 화면 너비 */
  z-index: 1000; /* 메뉴가 다른 요소 위에 위치하도록 */
  overflow: hidden; /* 메뉴가 펼쳐질 때만 보이도록 */
  grid-template-columns: 10px;
}

.dropdown li {
  text-align: center;
  list-style: none;
  margin-top: 10px;
}

.dropdown li a{
text-decoration: none;
color: #333;

}

.show {
  display: block;
  max-height: 500px; /* 메뉴의 최대 높이 */
}
/* 모바일 로고 */
.m-pflow {
  height: 34px;
  color: #3c3c40;
  font-family: Impact, var(--default-font-family);
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  text-align: left;
  white-space: nowrap;
  text-decoration: none;
  z-index: 22;
}

/* 모바일 로그인 버튼 */  
.m-login-btn {
margin-left: 120%;
width: 80px;
height: 30px;
background: #0554f2;
color: white;
border-radius: 6px;
border: none;
font-family: Pretendard, var(--default-font-family);
font-size: 12pt;
font-weight: 500;
white-space: nowrap;
text-decoration: none;
}
/*---------------------------------- 메인페이지 3섹션을 감싸는 태그---------------------*/
.main {
  margin-top: 130px;
  display: flex;
  flex-direction: column; /* 자식 요소를 수직으로 배치 */
  align-items: center; /* 자식 요소를 수평 중앙에 배치 */
  justify-content: center; /* 자식 요소 간의 수직 간격 조정 */
  gap: 60px; /* 자식 요소들 간의 간격 */
  position: relative;
  width: 100%;
  height: 2800px;
  z-index: 36;
}

.main-1 {
  display: flex;
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center;     /* 수직 중앙 정렬 */
  width: 100%;
  height: 100vh;
  padding-bottom: 200px;
}

@keyframes fadeIn {
  0% {
    opacity: 0; /* 시작할 때 투명 */
    transform: translateY(100px);
  }
  100% {
    opacity: 1; /* 끝에는 완전히 보이게 */
    transform: translateY(0); /* 원래 위치로 이동 */
  }
}

.fade-in {
  animation: fadeIn 2s ease-in forwards; /* 1초 동안 애니메이션 실행 */
}

.main-2 {
background-color: aliceblue;
display: flex;
justify-content: center; /* 수평 중앙 정렬 */
align-items: center;     /* 수직 중앙 정렬 */
width: 100%;
height: 100vh;
}


/* 마지막 섹션에서는 마진값없이 */
.main-3 {
  display: flex;
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center;     /* 수직 중앙 정렬 */
  width: 100%;
  height: 100vh;
}
/* 제목과 부제목 버튼을 감싼 영역*/
.content {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  position: relative;
}

/* 메인 페이지 버튼 속성*/
.icon {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 4px;
  position: relative;
  width: 14px;
  z-index: 46;
}

.icon-arrow {
  flex-shrink: 0;
  position: relative;
  width: 14px;
  height: 14px;
  background: url(../assets/images/5220dff0-fa4f-48a4-aa08-d26f8d11ef4d.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 47;
}

.main-btn-link {
  text-decoration-line: none;
}

/*------------------------- footer css 하단 고정--------------------------------------*/
.footer {
display: flex;
position: sticky; /* 부모 컨테이너에 대해 절대 위치 */
bottom: 0; /* 부모 컨테이너의 하단에 배치 */
align-items: left;
flex-direction: column;
gap: 30px;
width: 100%;
margin: 242px 0 0 0;
padding: 50px 200px 50px 200px;
background: #3c3c40;
color: white;
z-index: 99;
}

.logo-description {
display: flex;
flex-direction: column;
align-items: flex-start;
flex-wrap: nowrap;
flex-grow: 1;
flex-shrink: 0;
flex-basis: 0;
gap: 15px;
position: relative;
min-width: 0;
z-index: 102;
}
.logo-35 {
flex-shrink: 0;
position: relative;
width: 147px;
height: 34px;
z-index: 103;
}
.pflow-36 {
display: flex;
align-items: flex-start;
justify-content: flex-start;
position: absolute;
height: 34px;
top: 0;
left: 47px;
color: #ffffff;
font-family: Inter, var(--default-font-family);
font-size: 28px;
font-weight: 700;
line-height: 33.886px;
text-align: left;
white-space: nowrap;
z-index: 110;
}
.logo-icon-37 {
position: absolute;
width: 37px;
height: 29px;
top: 2.5px;
left: 0;
z-index: 104;
overflow: hidden;
}
.group-38 {
position: absolute;
width: 100%;
height: 100.01%;
top: 0;
left: 0;
z-index: 105;
}
.group-39 {
position: relative;
width: 37px;
height: 11.487px;
margin: 0 0 0 0;
z-index: 106;
}
.vector-3a {
position: absolute;
width: 32.76%;
height: 100%;
top: 0;
left: 0;
background: url(../assets/images/2807619d-ac74-4dd8-b499-60f2640232d8.png)
  no-repeat center;
background-size: 100% 100%;
z-index: 107;
}
.vector-3b {
position: absolute;
width: 32.74%;
height: 99.87%;
top: 0;
left: 67.26%;
background: url(../assets/images/7b0c6776-71ae-4a55-b236-5a8314993df8.png)
  no-repeat center;
background-size: 100% 100%;
z-index: 108;
}
.vector-3c {
position: relative;
width: 37px;
height: 26.458px;
margin: -8.942px 0 0 0;
background: url(../assets/images/5ea01471-30b1-49ca-bc58-c0fb73d2ed5d.png)
  no-repeat center;
background-size: 100% 100%;
z-index: 109;
}   

/* 여기서부터 푸터 수정하면됨...ㅂㄷㅂㄷ */

.footer-1 {
width: 100%;
min-width: 300px;
display: flex;
align-items: center;
justify-content: space-between;  
}
.info-tag {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info-tag a {
  display: inline-block;
  text-decoration: none;
  color: inherit; /* 부모에서 색상 상속 */
}

.footer-3 {
  min-width: 300px;
display: flex;
align-items: center;
justify-content: space-between;  
}
.btm-tag {
width: 40%;
display: flex;
align-items: center;
justify-content: space-between;  
}


.social-icon {
margin-top: 30px;
width: 10%;
display: flex;
justify-content: space-between;
}