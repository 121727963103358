.community {
    display: flex;
    flex-direction: column; /* 자식 요소를 세로로 나열 */
    align-items: center;     /* 수직 중앙 정렬 */
    width: 100%;
    height: auto;
    margin-top: 150px;
}

/*--------------loading animation css ------------------- */
.loading-page {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white; /* 배경을 반투명하게 */
  z-index: 999;
}

.loading-P {
    font-size: 28pt;
    font-weight: bolder;
    color: #C5DCF9;
    animation: changeColor 1.5s forwards infinite; 
    
}

.loading-F {
    font-size: 24pt;
    font-weight: bolder;
    color: #C5DCF9;
    animation: changeColor 1.5s forwards infinite; 
}

.loading-L {
    font-size: 24pt;
    font-weight: bolder;
    color: #C5DCF9;
    animation: changeColor 1.5s forwards infinite; 
}

.loading-O {
    font-size: 24pt;
    font-weight: bolder;
    color: #C5DCF9;
    animation: changeColor 1.5s forwards infinite; 
}

.loading-W {
    font-size: 24pt;
    font-weight: bolder;
    color: #C5DCF9;
    animation: changeColor 1.5s forwards infinite; 
}

.loading-P { animation-delay: 0s; }
.loading-F { animation-delay: 0.3s; } /* 0.4초 지연 */
.loading-L { animation-delay: 0.6s; } /* 0.8초 지연 */
.loading-O { animation-delay: 0.9s; } /* 1.2초 지연 */
.loading-W { animation-delay: 1.2s; } /* 1.6초 지연 */

@keyframes changeColor {
    0% {
      opacity: 1; /* 시작 시 투명 */
      color: #0554F2; /* 시작 색상 */
    }
   
    100% {
      opacity: 1; /* 최종적으로 보이게 */
      color: #C5DCF9; /* 최종 색상 */
    }
  }
/*--------------loading animation css end------------------- */

.commu-header {
    height: auto;
    display: flex;
    justify-content: space-between;
    min-width: 600px;
    padding: 5px;
    width: 720px; /* 테이블 너비를 100%로 설정 (필요에 따라 조정) */
    border-bottom: 1px solid #ccc; /* 검은색 실선 테두리 */
    margin-bottom: 20px;
}

.post-btn {
    margin-bottom: 10px;
    width: 100px;
    padding: 10px 10px 10px 10px;
    background-color: #0554f2;
    border: none;
    color: white;
}

.post-list {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-bottom: 20px;
    border-bottom: solid 1px #ccc;
    width: 720px;
    height: 140px;
}

.feed-link {
   text-decoration-line: none;
   color: #333;
}

.feed-footer {
    margin-left: 75%;
    display: flex;
    gap: 30px;
}

.feed-title {
    padding: 10px;
    margin-left: 35%;
}

.feed-view {
    font-size: 8pt;
    color: #818080;
}

.feed-date {
    font-size: 8pt;
    color: #818080;
}

.feed-user {
    font-size: 10pt;
    color: #818080;
}

/*-------글 작성하는 모달창-----*/
.modal-post {
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 배경을 반투명하게 */
  z-index: 999;
}

.post-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 자식 요소를 정확히 가운데로 이동 */
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center; /* 수직 가운데 정렬 */
    width: 50%;
    min-width: 600px;
    height: 80%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white; 
}

.close-btn {
    margin-top: 10px;
    margin-left: 90%;
    width: 30px;
    padding: 5px 10px 5px 10px;
    background-color: #0554f2;
    border: none;
    color: white;
}

.post-title-section {
    margin: 50px 0 20px 0;
    padding: 0 10px 0 10px;
    width: 80%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 5px; /* 모서리 둥글게 */
    display: flex; /* input을 블록 요소로 만듦 */ 
}


.post-section {
    margin: 5px 0 30px 0;
    padding: 0 10px 0 10px;
    width: 80%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 5px; /* 모서리 둥글게 */
    display: block; /* input을 블록 요소로 만듦 */ 
}

.post-title {
    border: none;
    width: 100%;
    height: 40px;
}

.post-category {
    margin: 5px 0 60px 0;
    padding: 0 10px 0 10px;
    width: 80%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px; /* 모서리 둥글게 */
    display: flex; /* input을 블록 요소로 만듦 */
}

.post-area {
    border: 2px solid #333;
    margin-top: 10px;
    border: none;
    width: 100%;
    height: 340px;
    resize: none;
}

.post-submit {
    margin-top: 10px;
    width: 100px;
    padding: 5px 10px 5px 10px;
    background-color: #0554f2;
    border: none;
    color: white;
}

/* 상단 총 포스팅 건 수 */
.counting-post {
    margin-top: 20px;
    width: 120px;
}

/* 카테고리 태그 영역 */
.category-section {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 20px;
    width: 600px;
}

.category-tag {
    margin: 5px;
    width: 110px;
    background-color: white;
    border-radius: 10px;
    border: solid 2px #C5DCF9;
    padding: 10px;
    transition: transform 0.2s;
   }

.category-tag:hover {
    transform: translateY(-5px); 
}
  

.category-tag.selected {
    border: solid 2px #0554f2;
   }

/*------------------ 인기글 영역 ------------------*/
.popular-feed-link {
    text-decoration-line: none;
    color: #333;
    margin-bottom: 80px;
    padding: 10px;
    border-radius: 10px;
    border: solid 3px #C5DCF9;
    width: 700px;
    height: 200px;
}

.popular-feed-link:hover {
    border: solid 3px #0554f2;
}

.popular-feed-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}

.popular-feed-content {
    font-size: 10pt;
    color: #818080;
}

.popular-feed-view {
 margin-left: 85%;
 font-size: 8pt;
 color: #818080;
}


/*-----------------------포스트 상세페이지----------------------------------*/

.detail {
    display: flex;
    flex-direction: column; /* 자식 요소를 세로로 나열 */
    align-items: center;     /* 수직 중앙 정렬 */
    width: 100%;
    height: auto;
    margin-top: 125px; 
}

.detail-form {
    display: flex;
    flex-direction: column; /* 자식 요소를 세로로 나열 */
    align-items: center;     /* 수직 중앙 정렬 */
    width: 1100px;
    height: auto;
}

.detail-header {
    font-size: 9pt;
    color: #818080;
    margin: 60px 30px 30px 30px;
    display: flex;
    justify-content: space-between;
    align-self: center;
    width: 80%;
    height: 60px;
    border-bottom: solid 1px #333;
}

.detail-header-1 {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 20px;
}


.detail-content {
    padding: 10px;
    margin: 20px;
    width: 900px;
    height: 320px;
    border-bottom: solid 1px #333;
    resize: none;
}


/*댓글입력하는*/
.comment {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* 댓글 리스트 전체 섹션 */
.comment-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    width: 90%;
    height: auto;
}

.comment-area {
    border: solid 1px #ccc;
    margin-top: 20px;
    padding: 20px;
    width: 900px;
    height: 80px;
    resize: none; 
}

.comment-table {
    margin-top: 30px;
    width: 90%;
    border-collapse: collapse;
    border-bottom: 1px solid #ccc;
    font-size: 9pt;
    text-align: center;
}


.comment-index {
    width: 80px;
}

.comment-content-td {
    padding-right: 30px;
    text-align: center;
    width: 420px;
}

.comment-btn-td {
    width: 120px;
}

/*----댓글 수정 모달-------------------------------------------------*/

.comement-modal-post {
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 배경을 반투명하게 */
    z-index: 999;
  }


  .comment-modify-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 자식 요소를 정확히 가운데로 이동 */
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center; /* 수직 가운데 정렬 */
    width: 600px;
    height: 600px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white; 
  }

  .comment-modify-area {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 400px;
    height: 400px;
    resize: none; 
  }

/*----btn section---------------------------------------------------*/

/* .movetolist-btn {
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: rgb(48, 42, 42);
    font-size: 18pt; 
} */

/* .movetolist-btn:hover {
    background-color: #f5f1f1;
} */

.user-post-btn {
 margin-left: 640px;
 display: flex;
 gap: 20px;

}

.post-modify-btn {
    width: 110px;
    height: 35px;
    padding: 5px 10px 5px 10px;
    background-color: #0554f2;
    border: none;
    color: white;
}

.post-modify-btn:hover {
    background-color: #0149da;
}

.comment-post-btn {
    margin-left: 65%;
    margin-top: 20px;
    width: 180px;
    height: 35px;
    padding: 5px 10px 5px 10px;
    background-color: #0554f2;
    border: none;
    color: white;
}

.comment-post-btn:hover {
    background-color: #0149da;
}

.comment-del-btn {
    background-color: white;
    border: solid 1px #ccc;
    margin: 5px;
}

.comment-del-btn:hover {
    background-color: #f5f1f1;
    
}