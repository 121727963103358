.container {
  margin-top: 130px; /* 필요한 경우 여백 유지 */
  width: 100%;
  height: 100%; /* 부모 요소의 높이를 화면 크기에 맞게 설정 */
  display: flex;
  align-items: center; /* 세로축 가운데 정렬 */
  flex-direction: column; /* 자식 요소를 수직으로 정렬 */
}
.progress-div {
    width: 60%;
    display: flex;
    align-items: center; /* 세로축 가운데 정렬 */
    flex-direction: column; /* 자식 요소를 수직으로 정렬 */
}
/*-----resume4---------------------------------------------------------------------*/

.final-step {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  width: 100%;
  height: auto;
  flex-direction: column;
}

.result-section {
  border-top: solid 2px #ccc;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center; /* 수직 가운데 정렬 */
}

/*---------completed resume-----------------------------------*/


.resume {
  padding: 20px;
    min-width: 600px;
    width: 800px;
    display: flex;
    flex-direction: column;
    align-items: left; /* 수직 가운데 정렬 */
}



/*------btn section-----------------------------------------------------------*/

.btn-section {
  margin-top: 100px;
  gap: 30px;
  width: 800px;
  display: flex;
  justify-content: space-between;
  align-items: right; /* 수직 가운데 정렬 */
}

.download-btn {
  font-size: 11pt;
  font-weight: bold;
  border: solid 1PX #3889F2;
  width: 110px;
  height: 30px;
  color: #3889F2;
  background-color: #fff;
}

.download-btn:hover {
  border: solid 2px #0554F2;
  color: #0554F2;
}


.gotohome-btn {
  font-size: 11pt;
  font-weight: bold;
  border: solid 1PX #3889F2;
  width: 110px;
  height: 30px;
  color: #3889F2;
  background-color: #fff;
}

.gotohome-btn:hover {
  border: solid 2px #0554F2;
  color: #0554F2;
}

.img {
  margin-right: 20px;
} 