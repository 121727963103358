.container {
  margin-top: 130px; /* 필요한 경우 여백 유지 */
  width: 100%;
  height: 100%; /* 부모 요소의 높이를 화면 크기에 맞게 설정 */
  display: flex;
  align-items: center; /* 세로축 가운데 정렬 */
  flex-direction: column; /* 자식 요소를 수직으로 정렬 */
}
.progress-div {
    width: 60%;
}
/*-----resume4---------------------------------------------------------------------*/
.resume-4 {
  border-top: solid 1px #333;
  border-bottom: solid 1px #333;
  width: 60%;
  height: auto;
  display: flex;
  justify-content: space-between;
}

/*----- 문구 추천 영역 -----*/
.recommend-div {
  margin-top: 20px;
  width: 40%;
  min-width: 460px;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.recommend-p {
  color: #333;
  font-size: 9pt;
  padding: 15px;
}

.recommend-ul {
  margin-top: 40px;
  display: flex;
  flex-direction: column;

  gap: 10px; /* 아이템들 사이의 간격 */
}

.recommend-li {
  font-size: 9pt;
  padding: 20px;
  width: 360px;
  height: 330px;
  list-style: none;
  background-color: #fff;
  border-radius: 5px; /* 모서리 둥글게 */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
}

.recommend-li:hover {
  background-color: aliceblue;
}

/*--------loading animation------------------------------------------------------*/

.spinner-container {
  display: flex;
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
  height: 100px; /* 로딩 애니메이션의 높이 지정 */
  width: 100%; /* 가로폭을 부모 요소에 맞추기 */
}

.spinner {
  border: 4px solid #f3f3f3; /* 밝은 회색 테두리 */
  border-top: 4px solid #3498db; /* 상단 테두리의 색상 */
  border-radius: 50%; /* 둥근 모양으로 만들기 */
  width: 40px; /* 스피너의 너비 */
  height: 40px; /* 스피너의 높이 */
  animation: spin 1s linear infinite; /* 회전 애니메이션 설정 */
}

/* 애니메이션 정의 */
@keyframes spin {
  0% {
    transform: rotate(0deg); /* 시작 위치 */
  }
  100% {
    transform: rotate(360deg); /* 360도 회전 */
  }
}

/*--------loading animation------------------------------------------------------*/

.copy-sign {
  font-size: 9pt;
  padding: 5px;
  width: 80px;
  height: 30px;
  color: white;
  background-color: #6FABFA;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  text-align: center;
  position: relative;
  top: -96%;
  left: -10%;
  visibility: visible;
  opacity: 0;
  transition: visibility 0s 2s, opacity 0.3s ease-in-out; /* 3초 후에 visibility가 변경되도록 설정 */
  animation: fadeOut 3.3s forwards; /* 애니메이션 시간과 함께 opacity가 0으로 변함 */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1; /* 애니메이션의 90%까지는 완전히 보이도록 유지 */
  }
  100% {
    opacity: 0; /* 애니메이션 끝에 완전히 사라짐 */
    visibility: hidden; /* 사라질 때 visibility를 hidden으로 설정 */
  }
}

.hidden {   /* 기본적으로 문구추천 텍스트 받아오기전에 안보이는 상태. */
  opacity: 0;
}

.copy-sign::after {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: #6FABFA transparent transparent transparent;
}

.recommend-post-div1 {
  display: flex;
  width: 100%;
  gap: 40px;
 margin-bottom: 10px;
 margin-left: 40px;
}

.recommend-post-div2 {
  display: flex;
  width: 100%;
  gap: 15px;
}

.theme-btn {
  font-size: 8pt;
  width: 65px;
  height: 65px;
  border: solid 1px #C5DCF9;
  border-radius: 50px;
  background-color: white;
  padding: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #6FABFA;
}
.theme-btn:hover {
  border: solid 2px #007BFF;
  color: #007BFF;
}

.theme-btn.selected {
  border: solid 2px #007BFF;
  color: #007BFF;
}

.recommend-input {
  padding: 10px;
  border: solid 1px #6FABFA;
  font-size: 8pt;
  width: 240px;
  height: 35px;
  border-radius: 5px;
  background-color: #fff;
  margin-left: 40px;
}

.recommend-btn {
  width: 110px;
  height: 35px;
  border: none;
  border-radius: 10px;
  color: white;
  background-color:  #007BFF;
}

.recommend-btn:hover {
  background-color:  #6FABFA;
}


/*----이력서 작성 영역------*/
.form-4 {
  margin-top: 20px;
  padding: 10px;
  width: 60%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.intro-section {
  width: 100%;
  height: 600px;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.title-section {
  width: 100%;
  height: 80px;
}
.textarea-section {
  width: 100%;
  height: 100%;
}
/*-------input css----------------------------------------------------*/
.input-image {
  display: none;
}

.img-label {
  display: inline-block;
  padding: 25px 15px;
  font-size: 11pt;
  font-weight: bold;
  color: #007BFF;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  border: solid 1px #007BFF;
}

.img-label:hover {
  background-color: #007BFF;
  color: white;
}


/* preview가 있을 때 label 숨기기 */
.hidden {
  display: none;   
}
.input-title {
  margin-top: 30px;
  padding: 20px;
  width: 100%;
  background-color:aliceblue;
  border: none;
}
.input-intro {
  margin-top: 10px;
  padding: 20px;
  width: 100%;
  height: 95%;
  background-color:aliceblue;
  border-top: solid 1px #333;
  border: none;
  resize: none;
}